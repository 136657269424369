import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.scss'
import Homepage from './pages/Homepage'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom'
import EshkaTaxi from './pages/EshkaTaxi'
import ValleyList from './pages/ValleyList'
import ArgusSoft from './pages/ArgusSoft'
import Usign from './pages/Usign'
import BankGrant from './pages/BankGrant'
import EnlilMill from './pages/EnlilMill'
import Sketchpad from './pages/Sketchpad'
import Mysimplelog from './pages/Mysimplelog'
import Outspace from './pages/Outspace'
import Gadgetly from './pages/Gadgetly'

const root = ReactDOM.createRoot(document.getElementById('root'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Homepage />,
  },
  {
    path: '/EshkaTaxi',
    element: <EshkaTaxi />,
  },
  {
    path: '/ValleyList',
    element: <ValleyList />,
  },
  {
    path: '/ArgusSoft',
    element: <ArgusSoft />,
  },
  {
    path: '/Usign',
    element: <Usign />,
  },
  {
    path: '/BankGrant',
    element: <BankGrant />,
  },
  {
    path: '/EnlilMill',
    element: <EnlilMill />,
  },
  {
    path: '/Sketchpad',
    element: <Sketchpad />,
  },
  {
    path: '/Mysimplelog',
    element: <Mysimplelog />,
  },
  {
    path: '/Outspace',
    element: <Outspace />,
  },
  {
    path: '/Gadgetly',
    element: <Gadgetly />,
  },
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
)

reportWebVitals()
