import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/Gadgetly/1.jpg';
import img2 from '../images/Portfolio/Gadgetly/2.jpg';
import img3 from '../images/Portfolio/Gadgetly/3.jpg';
import img4 from '../images/Portfolio/Gadgetly/4.jpg';
import img5 from '../images/Portfolio/Gadgetly/5.jpg';
import img6 from '../images/Portfolio/Gadgetly/6.jpg';
import img7 from '../images/Portfolio/Gadgetly/7.jpg';
import img8 from '../images/Portfolio/Gadgetly/8.jpg';
import img9 from '../images/Portfolio/Gadgetly/9.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/GeneralPortfolio.module.scss'

function EshkaTaxi() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Gadgetly'
  const text =
    'Gadgelty is your one-stop online shop for kitchen essentials and pet products. Our app streamlines the customer experience, letting them quickly fill out a brief, attach references, and place orders with ease. The playful design adds a touch of fun to the process. Explore our curated selection of kitchen accessories and pet items conveniently on Inspace\'s user-friendly platform.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img2}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img3}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img4}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img5}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img6}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img7}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img8}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
      src={img9}
      className={styles.GeneralBigImage}
      alt="Gadgetly app interface"
    />,
    <img
    src={img1}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img2}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img3}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img4}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img5}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img6}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img7}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img8}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  <img
    src={img9}
    className={styles.GeneralBigImage}
    alt="Gadgetly app interface"
  />,
  ]
  return (
    <div className={styles.General}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.figma.com/file/UG9uKfPd1qVKhw7ksHYdIr/Gadgetly.Store-(e-commerce)?type=design&mode=design&t=PMfcCvqzWCgJ9wYf-0'/>
    </div>
  )
}

export default EshkaTaxi
