import React from 'react'
import styles from '../styles/PortfolioPage.module.scss'
import BackBtn from '../images/BackBtn.svg'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

function PortfolioPage({ title, text, images, src }) {
  const navigate = useNavigate()
  return (
    <div className={styles.PortfolioPage}>
      <div className={styles.PortfolioPageHeader}>
        <div
          className={styles.PortfolioPageBackBtnWrapper}
        >
            <img
              onClick={() => navigate('/',{state:{isPrevLocationPorfolio: true}})}

              src={BackBtn}
              alt="Go back button"
              className={styles.PortfolioPageBackBtn}
            />
        </div>
        <div className={styles.PortfolioPageHeaderInfo}>
          <div className={styles.PortfolioPageHeaderInfoTitle}>{title}</div>
          <div className={styles.PortfolioPageHeaderInfoText}>{text}</div>
        </div>
      </div>
      <div className={styles.Portfolio}>
        <div className={styles.PortfolioSwiperWrapper}>
          <Swiper
            className={styles.PortfolioSwiper}
            slidesPerView={'auto'}
            loop
            roundLengths
          >
            {images.map((element, index) => {
              return (
                <SwiperSlide
                  className={styles.PortfolioSwiperSlide}
                  key={index}
                >
                  {element}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
      <div className={styles.PortfolioPageDesignBtnWrapper}>
        <a href={src}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.PortfolioPageDesignBtn}
        >
          Check the design
        </a>
      </div>
      <div className={styles.PortfolioPageLine}></div>
      <div className={styles.PortfolioPageTextDot}></div>
      <div className={styles.PortfolioPageText}>Let&#8217;s talk? </div>
      <Footer portfolio={true}/>
    </div>
  )
}

export default React.memo(PortfolioPage)
