import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/Sketchpad/1.jpg';
import img2 from '../images/Portfolio/Sketchpad/2.jpg';
import img3 from '../images/Portfolio/Sketchpad/3.jpg';
import img4 from '../images/Portfolio/Sketchpad/4.jpg';
import img5 from '../images/Portfolio/Sketchpad/5.jpg';
import img6 from '../images/Portfolio/Sketchpad/6.jpg';
import img7 from '../images/Portfolio/Sketchpad/7.jpg';
import img8 from '../images/Portfolio/Sketchpad/8.jpg';
import img9 from '../images/Portfolio/Sketchpad/9.jpg';
import img10 from '../images/Portfolio/Sketchpad/10.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/Sketchpad.module.scss'

function Sketchpad() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Sketchpad'
  const text =
    'SketchPad, an online art course brand, bridges the gap between budding artists and their creative aspirations. Our UI/UX design studio took on the challenge of crafting an engaging and intuitive digital space where aspiring artists can nurture their talents.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.SketchpadImage1}
      alt="Sketchpad web interface"
    />,
    <img
      src={img2}
      className={styles.SketchpadImage2}
      alt="Sketchpad web interface"
    />,
    <img
      src={img3}
      className={styles.SketchpadImage3}
      alt="Sketchpad web interface"
    />,
    <img
      src={img4}
      className={styles.SketchpadImage4}
      alt="Sketchpad web interface"
    />,
    <img
      src={img5}
      className={styles.SketchpadImage5}
      alt="Sketchpad web interface"
    />,
    <img
      src={img6}
      className={styles.SketchpadImage6}
      alt="Sketchpad web interface"
    />,
    <img
    src={img7}
    className={styles.SketchpadImage7}
    alt="Sketchpad web interface"
  />,
  <img
    src={img8}
    className={styles.SketchpadImage8}
    alt="Sketchpad web interface"
  />,
  <img
    src={img9}
    className={styles.SketchpadImage9}
    alt="Sketchpad web interface"
  />,
  <img
    src={img10}
    className={styles.SketchpadImage10}
    alt="Sketchpad web interface"
  />,
  ]
  return (
    <div className={styles.Sketchpad}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.behance.net/gallery/172316761/Drawing-studio'/>
    </div>
  )
}

export default Sketchpad
