import React from 'react'
import styles from '../styles/Footer.module.scss'
import Arrow from '../images/Arrow.svg'
import ContactBtn from './ContactBtn'

function Footer({portfolio = false}) {
  function topFunction() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
      <ContactBtn portfolio={portfolio}/>
        <div className={styles.footerWrapper}>
          <div className={styles.footerText}>© Sidscape studio 2023</div>

          <div
            className={`${styles.footerText} ${styles.btn}`}
            onClick={topFunction}
          >
            <img
              className={styles.footerTextArrow}
              src={Arrow}
              alt="arrow up"
            />
            Go all the way up
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
