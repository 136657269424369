import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/ValleyList/1.jpg';
import img2 from '../images/Portfolio/ValleyList/2.jpg';
import img3 from '../images/Portfolio/ValleyList/3.jpg';
import img4 from '../images/Portfolio/ValleyList/4.jpg';
import img5 from '../images/Portfolio/ValleyList/5.jpg';
import img6 from '../images/Portfolio/ValleyList/6.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/ValleyList.module.scss'

function ValleyList() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Valley list'
  const text =
    'The Valley List is a family-owned business that focuses on valuing the right solution. The logic of the site was developed, as well as the visual style, which perfectly fulfilled the task of attracting the attention of potential customers and providing them with information.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.ValleyListImage1}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img2}
      className={styles.ValleyListImage2}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img3}
      className={styles.ValleyListImage3}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img4}
      className={styles.ValleyListImage4}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img5}
      className={styles.ValleyListImage5}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img6}
      className={styles.ValleyListImage6}
      alt="Eshka Taxi app interface"
    />,
    <img
    src={img1}
    className={styles.ValleyListImage1}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img2}
    className={styles.ValleyListImage2}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img3}
    className={styles.ValleyListImage3}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img4}
    className={styles.ValleyListImage4}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img5}
    className={styles.ValleyListImage5}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img6}
    className={styles.ValleyListImage6}
    alt="Eshka Taxi app interface"
  />,
  ]
  return (
    <div className={styles.ValleyList}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://thevalleylist.com/'/>
    </div>
  )
}

export default ValleyList
