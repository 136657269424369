import React, { useEffect, useState } from 'react'
import styles from '../styles/Portfolio.module.scss'
import PortfolioItem from './PortfolioItem'
import ArgusSoft from '../images/ArgusSoft.png'
import BankGrant from '../images/BankGrant.png'
import EnlilMill from '../images/EnlilMill.png'
import EshkaTaxi from '../images/EshkaTaxi.png'
import Usign from '../images/Usign.png'
import Outspace from '../images/Outspace.jpg'
import Mysimplelog from '../images/Mysimplelog.jpg'
import Sketchpad from '../images/Sketchpad.jpg'
import ValleyList from '../images/ValleyList.png'
import Gadgetly from '../images/Portfolio/Gadgetly/1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

function Portfolio({portfolioWrapperRef, setIsBackFromPortfolio}) {

  let [filtered, setFiltered] = useState([])
  let [portfolioList, setPortfolioList] = useState([])

  const PortfolioImages = [
    {
      img: EshkaTaxi,
      tag: ['Mobile Applications'],
      backgroundColor: '',
      link: '/EshkaTaxi',
    },
    {
      img: EnlilMill,
      tag: ['Graphic Design', 'Packaging', 'Corporate Websites'],
      backgroundColor: 'Purple',
      link: '/EnlilMill',
    },
    {
      img: BankGrant,
      tag: ['Mobile Applications', 'Logotype'],
      backgroundColor: '',
      link: '/BankGrant',
    },
    {
      img: Usign,
      tag: ['Web Services'],
      backgroundColor: 'Green',
      link: '/Usign',
    },
    {
      img: ArgusSoft,
      tag: ['Corporate Websites', 'Logotype', 'Company Graphics'],
      backgroundColor: '',
      link: '/ArgusSoft',
    },
    {
      img: ValleyList,
      tag: ['Corporate Websites'],
      backgroundColor: 'Purple',
      link: '/ValleyList',
    },
    {
      img: Sketchpad,
      tag: ['Landing Pages'],
      backgroundColor: '',
      link: '/Sketchpad',
    },
    {
      img: Outspace,
      tag: ['Mobile Applications', 'Company Graphics'],
      backgroundColor: 'Green',
      link: '/Outspace',
    },
    {
      img: Mysimplelog,
      tag: ['Corporate Websites'],
      backgroundColor: '',
      link: '/Mysimplelog',
    },
    {
      img: Gadgetly,
      tag: ['Corporate Websites', 'E-commerce'],
      backgroundColor: 'Purple',
      link: '/Gadgetly',
    }
  ]

  function addFilter(tag) {
    if (tag === 'Clear All') {
      setFiltered([])
      return
    }
    if (filtered.includes(tag)) {
      setFiltered(filtered.filter(el => el !== tag))
    } else {
      setFiltered([...filtered, tag])
    }
  }

  function filterPortfolio(filtered, PortfolioImages) {
     let arr = PortfolioImages.filter(portfolio => {
      return  portfolio.tag.some(tag => {
        return filtered.includes(tag)
      })
    })
    return arr
  }

  useEffect(()=> {
    if(filtered.length > 0) {
      setPortfolioList(filterPortfolio(filtered, PortfolioImages))
    } else {
      setPortfolioList(repeat(PortfolioImages, 2))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered])


function repeat(a, n, r) {
    return !n ? r : repeat(a, --n, (r||[]).concat(a));
}
  const portfolioTags = [
    'Landing Pages',
    'Corporate Websites',
    'Web Services',
    'Mobile Applications',
    'E-commerce',
    'Graphic Design',
    'Logotype',
    'Company Graphics',
    'Packaging',
    'Clear All'
  ]

  return (
    <>
      <div className={styles.Portfolio} ref={portfolioWrapperRef}>
        <div className={styles.PortfolioHeaderContainer}>
          <div className={styles.PortfolioHeaderWrapper}>
            <div className={styles.PortfolioHeader}>
              {'Branding design Digital design '.repeat(40)}
            </div>
          </div>
        </div>
        <div className={styles.PortfolioTagsSectionWrapper}>
          <div className={styles.PortfolioTagsSection}>
            {portfolioTags.map((tag, index) => {
              return (
                <div className={`${styles.PortfolioTagsSectionElement} ${ filtered.includes(tag) ? styles.PortfolioTagsSectionElementFilter : ""} ${ tag === 'Clear All' && filtered.length >= 1 ? styles.PortfolioTagsSectionElementClearAll : ''} `} onClick={() => addFilter(tag)} key={index} >
                  <span>{tag}</span>
                </div>
              )
            })}
          </div>
        </div>

         <div className={styles.PortfolioSwiperWrapper}>
          <Swiper
            className={styles.PortfolioSwiper}
            slidesPerView={'auto'}
            // resistance={false}
            // isActive={true}
            // parallax={true}
            // snapOnRelease={true}
            // loopAdditionalSlides={filtered.length}
            // slidesPerView={slidesPerView()}
            // slidesPerGroupAuto={true}
            // resistance={'100%'}   

            spaceBetween={40}
            speed={1000}
            loop={filtered.length === 0}
            breakpoints={{
              320: {
                spaceBetween: 22
              },
              1366: {
                spaceBetween: 40
              }
            }}
          >
            {portfolioList.map((element, index) => {
              return (
                  <SwiperSlide
                    className={styles.PortfolioSwiperSlide}
                    key={index}
                  >
                    <PortfolioItem
                      image={element.img}
                      tag={element.tag}
                      color={element.backgroundColor}
                      link={element.link}
                      filtered={filtered}
                    />
                  </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default React.memo(Portfolio)
