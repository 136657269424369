import React, { useEffect, useState } from 'react'
import styles from '../styles/Header.module.scss'
import lines from '../images/Lines.svg'
import ArrowWithoutCircle from '../images/ArrowWithoutCircle.svg'
import ContactBtn from './ContactBtn'
import {useLocation, useNavigate} from 'react-router-dom';

function Header({portfolioRef = null}) {
    let moment = require('moment-timezone');
    const location = useLocation();
    const navigate = useNavigate();
    const [isPrevLocationPortfolio, setIsPrevLocationPortfolio] = useState(location?.state?.isPrevLocationPorfolio || false)
    const [BrnoTime, setBrnoTime] = useState('')
    const [KyivTime, setKyivTime] = useState('')
    function handleScrollToResults() {
        if(portfolioRef.current) {
            portfolioRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }

    useEffect(() => {
        if(isPrevLocationPortfolio) {
            handleScrollToResults()
            setIsPrevLocationPortfolio(false)
            navigate("", { isPrevLocationPorfolio: false });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrevLocationPortfolio, navigate])

    useEffect( () => {
        let interval = setInterval(
             () => {
                setBrnoTime(`${+moment().utcOffset('+02:00').format("HH")}:${moment().utc().format("mm") + " Brno, Czech Republic"}`)
                setKyivTime(`${+moment().utcOffset('+03:00').format("HH")}:${moment().utc().format("mm") + " Kyiv, Ukraine"}`)
            }, 1000
        )
        return () => clearInterval(interval)
    },[moment])

  return (
    <div className={styles.Header}> 
    <div className={styles.HeaderContainer}>  
        <div className={styles.HeaderLeftContainer}>
            <div className={styles.HeaderLeftContainerWrapper}>
                <div className={styles.HeaderLeftContainerWrapperLetters}>                
                <div  className={`${styles.HeaderLeftContainerLetterS}`}></div>
            
                    <div className={styles.HeaderContactBtnWrapper}>
                        <div className={styles.HeaderContactBtn}><ContactBtn footerPlacement={false}/></div>
                        <div  className={`${styles.HeaderLeftContainerLetterI}`}></div>
                    </div>
                    <div  className={`${styles.HeaderLeftContainerLetterD}`}></div>
                </div>
            </div>
            <img src={lines} className={styles.HeaderLeftContainerLines} alt="horizontal lines"/>
        </div>
        <div className={`${styles.HeaderRightContainer}`} >
            <div className={`${styles.HeaderRightContainerTop}`}>
                <div className={`${styles.HeaderRightContainerTime}`}>{KyivTime}</div>
                <div className={`${styles.HeaderRightContainerTime}`}>{BrnoTime}</div>  
            </div>
            <div className={`${styles.HeaderRightContainerBottom}`}>
                <div className={`${styles.HeaderRightContainerText}`}>We create design - <br/> with creation, attention and love. </div>
                <div className={`${styles.HeaderRightContainerArrowDiv}`} onClick={handleScrollToResults}>
                    <img src={ArrowWithoutCircle}  className={`${styles.HeaderRightContainerArrow}`}  alt="button down"/>
                </div>
                
            </div>
            
        </div>
    </div>

    <div className={styles.HeaderBottom}>

        <div className={styles.HeaderLine}></div>
        <div className={styles.HeaderTextDot}></div>
        <div className={styles.HeaderTextOffer}>What we can offer to you?</div>
    </div>
    
    </div>

  )
}

export default Header
