import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/ArgusSoft/1.jpg';
import img2 from '../images/Portfolio/ArgusSoft/2.jpg';
import img3 from '../images/Portfolio/ArgusSoft/3.jpg';
import img4 from '../images/Portfolio/ArgusSoft/4.jpg';
import img5 from '../images/Portfolio/ArgusSoft/5.jpg';
import img6 from '../images/Portfolio/ArgusSoft/6.jpg';
import img7 from '../images/Portfolio/ArgusSoft/7.jpg';
import img8 from '../images/Portfolio/ArgusSoft/8.jpg';
import img9 from '../images/Portfolio/ArgusSoft/9.jpg';
import img10 from '../images/Portfolio/ArgusSoft/10.jpg';
import img11 from '../images/Portfolio/ArgusSoft/11.png';

import React, { useEffect } from 'react'
import styles from '../styles/ArgusSoft.module.scss'

function ArgusSoft() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Argus soft'
  const text =
    'The task was to think over and create a design for the Ukrainian IT company "Argus Soft". An analysis of the market and target audience was carried out to better understand the requirements for creating a website. CJM was developed, followed by prototyping and the creation of a full-fledged website layout for a computer and adaptives: a tablet and a phone.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.ArgusSoftImage1}
      alt="Argus soft interface"
    />,
    <img
      src={img2}
      className={styles.ArgusSoftImage2}
      alt="Argus soft interface"
    />,
    <img
      src={img3}
      className={styles.ArgusSoftImage3}
      alt="Argus soft interface"
    />,
    <img
      src={img4}
      className={styles.ArgusSoftImage4}
      alt="Argus soft interface"
    />,
    <img
      src={img5}
      className={styles.ArgusSoftImage5}
      alt="Eshka Taxi interface"
    />,
    <img
      src={img6}
      className={styles.ArgusSoftImage6}
      alt="Argus soft interface"
    />,
    <img
    src={img7}
    className={styles.ArgusSoftImage7}
    alt="Argus soft interface"
  />,
  <img
    src={img8}
    className={styles.ArgusSoftImage8}
    alt="Argus soft interface"
  />,
  <img
    src={img9}
    className={styles.ArgusSoftImage9}
    alt="Argus soft interface"
  />,
  <img
    src={img10}
    className={styles.ArgusSoftImage10}
    alt="Argus soft interface"
  />,
  <img
    src={img11}
    className={styles.ArgusSoftImage11}
    
    alt="Argus soft interface"
  />,
  ]
  return (
    <div className={styles.ArgusSoft}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://argussoft.ai/' />
    </div>
  )
}

export default ArgusSoft
