import React from 'react'
import styles from '../styles/AboutUs.module.scss'
import Creation from '../images/Creation.png'
import Attention from '../images/Attention.png'
import Love from '../images/Love.png'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

function AboutUs() {
  let creationRef = React.useRef()
  let attentionRef = React.useRef()
  let loveRef = React.useRef()

  function showLog(refImg) {
    if (refImg !== null) {
      refImg.current.style.visibility = 'visible'
      refImg.current.style.opacity = '1'
    }
  }

  function hideLog(refImg) {
    if (refImg !== null) {
      refImg.current.style.opacity = '0'
      refImg.current.style.visibility = 'hidden'
    }
  }

  return (
    <>
      <section className={styles.AboutUs}>
        <div className={styles.AboutUsMainSection}>
          <div className={styles.AboutUsMainSectionRight}>
            <div className={styles.AboutUsMainSectionRightCredoWrapper}>
              <div className={styles.AboutUsMainSectionRightCredo}>
                We believe in
              </div>
              <div className={styles.AboutUsMainSectionRightCredoTextWrapper}>
                <div
                  className={styles.AboutUsMainSectionRightCredoText}
                  onMouseOver={() => showLog(creationRef)}
                  onMouseOut={() => hideLog(creationRef)}
                  data-aos="fade-up"
                  data-aos-duration="300"
                  data-aos-easing="ease-in-out"
                  data-aos-once="false"
                  data-aos-anchor-placement="center-bottom"
                >
                  Creation.
                </div>
                <div
                  className={styles.AboutUsMainSectionRightCredoText}
                  onMouseOver={() => showLog(attentionRef)}
                  onMouseOut={() => hideLog(attentionRef)}
                  data-aos="fade-up"
                  data-aos-duration="300"
                  data-aos-easing="ease-in-out"
                  data-aos-once="false"
                  // data-aos-mirror="true"
                  data-aos-anchor-placement="center-bottom"
                  // data-aos-anchor-placement="center-center"
                >
                  Attention.
                </div>
                <div
                  className={styles.AboutUsMainSectionRightCredoText}
                  onMouseOver={() => showLog(loveRef)}
                  onMouseOut={() => hideLog(loveRef)}
                  data-aos="fade-up"
                  data-aos-duration="300"
                  data-aos-easing="ease-in-out"
                  data-aos-anchor-placement="center-bottom"
                >
                  Love.
                </div>
              </div>
            </div>
            <div className={styles.AboutUsMainSectionRightKeyText}>
              We are a design studio specializing in UI/UX design and graphic
              design. We are passionate about creating visually stunning and
              highly functional designs that engage and inspire users. Our team of
              skilled designers works closely with clients to deliver customized
              solutions that meet their unique needs and goals. From concept to
              execution, we bring creativity, innovation, and attention to detail
              to every project we undertake. Whether you are looking for a new
              website, app, or branding materials, Sidscape Studio has the
              expertise and experience to bring your vision to life.
            </div>
          </div>
          <div className={styles.AboutUsMainSectionLeft}>
            <img
              src={Creation}
              className={styles.AboutUsMainSectionLeftImageCreation}
              alt="Creation meaning"
              ref={creationRef}
            />
            <img
              src={Attention}
              className={styles.AboutUsMainSectionLeftImageAttention}
              alt="Attention meaning"
              ref={attentionRef}
            />
            <img
              src={Love}
              className={styles.AboutUsMainSectionLeftImageLove}
              alt="Love meaning"
              ref={loveRef}
            />
          </div>
        </div>

        <div className={styles.AboutUsTextDot}></div>
        <div className={styles.AboutUsText}>Let&#8217;s talk? </div>
      </section>
    </>
    
  )
}

export default AboutUs
