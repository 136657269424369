import React, { useState } from 'react'
import Xmark from '../images/Xmark.svg'
import TelegramIcon from '../images/TelegramIcon.svg'
import styles from '../styles/ContactBtn.module.scss'
import Draggable from "react-draggable";

function ContactBtn({footerPlacement = true, portfolio = false, safari = false}) {
  let [isOpen, setIsOpen] = useState(false)
  function contactBtnPlacement (isOpen) {
    if(isOpen) {
      return (footerPlacement ? styles.ContactBtnOpen : styles.ContactBtnOpenTop)
    } else {
      return (footerPlacement ? styles.ContactBtn : styles.ContactBtnTop)
    }
  }
  return (
    <div className={`${footerPlacement ? styles.ContactBtnWrapper : ""} ${safari ? styles.ContactBtnWrapperSafari : ""}`}>
      <div
        className={`${contactBtnPlacement(isOpen)} ${
          styles.ContactBtn
        } `}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.ContactBtnText}>Contact</div>
      </div>
      {isOpen && (
        <Draggable 
          bounds={'body'}
          cancel=".clickable"
        >
            <div className={`${footerPlacement ? styles.ContactBtnModal : styles.ContactBtnModalTop} ${portfolio ? styles.ContactBtnPortfolioModal : ''} ${safari ? styles.ContactBtnModalSafari : ""}`}>
              
              <div className={styles.ContactBtnModalCloseWrapper}>
                <img
                  src={Xmark}
                  alt="close button"
                  className={styles.ContactBtnModalClose + " clickable"}
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>

              <div className={styles.ContactBtnModalWrapper}>
                <a
                  href="tel:+380962919117"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ContactBtnModalText  + " clickable"}
                >
                  
                </a>
                <a
                  href="mailto:sarkis.symovyan@sidscape.studio"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ContactBtnModalText  + " clickable"}
                >
                  sarkis.symovyan@sidscape.studio
                </a>
                <a
                  href="https://t.me/sidscapestudio"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.ContactBtnModalText  + " clickable"}
                >
                  <img
                    src={TelegramIcon}
                    alt="telegram icon"
                    className={styles.ContactBtnModalTelegram  + " clickable"}
                  />
                </a>
              </div>
            </div>
        </Draggable>
      )}
    </div>
  )
}

export default ContactBtn