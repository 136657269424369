import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/EshkaTaxi/1.jpg';
import img2 from '../images/Portfolio/EshkaTaxi/2.jpg';
import img3 from '../images/Portfolio/EshkaTaxi/3.jpg';
import img4 from '../images/Portfolio/EshkaTaxi/4.jpg';
import img5 from '../images/Portfolio/EshkaTaxi/5.jpg';
import img6 from '../images/Portfolio/EshkaTaxi/6.jpg';
import img7 from '../images/Portfolio/EshkaTaxi/7.jpg';
import img8 from '../images/Portfolio/EshkaTaxi/8.jpg';
import img9 from '../images/Portfolio/EshkaTaxi/9.jpg';
import img10 from '../images/Portfolio/EshkaTaxi/10.jpg';
import img11 from '../images/Portfolio/EshkaTaxi/11.jpg';
import img12 from '../images/Portfolio/EshkaTaxi/12.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/EshkaTaxi.module.scss'

function EshkaTaxi() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Eshka Taxi'
  const text =
    'Eshka taxi is a premium class taxi that started its work in the city of Kyiv and intends to expand to the national level. This is a premium class taxi, the fleet of which consists exclusively of Mercedes cars. Our studio carried out a large amount of work: competitor analysis, creation pf application logic and creating a design for user, driver, and admin interfaces.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.EshkaTaxiImage1}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img2}
      className={styles.EshkaTaxiImage2}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img3}
      className={styles.EshkaTaxiImage3}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img4}
      className={styles.EshkaTaxiImage4}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img5}
      className={styles.EshkaTaxiImage5}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img6}
      className={styles.EshkaTaxiImage6}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img7}
      className={styles.EshkaTaxiImage7}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img8}
      className={styles.EshkaTaxiImage8}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img9}
      className={styles.EshkaTaxiImage9}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img10}
      className={styles.EshkaTaxiImage10}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img11}
      className={styles.EshkaTaxiImage11}
      alt="Eshka Taxi app interface"
    />,
    <img
      src={img12}
      className={styles.EshkaTaxiImage12}
      alt="Eshka Taxi app interface"
    />,
    <img
    src={img1}
    className={styles.EshkaTaxiImage1}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img2}
    className={styles.EshkaTaxiImage2}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img3}
    className={styles.EshkaTaxiImage3}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img4}
    className={styles.EshkaTaxiImage4}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img5}
    className={styles.EshkaTaxiImage5}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img6}
    className={styles.EshkaTaxiImage6}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img7}
    className={styles.EshkaTaxiImage7}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img8}
    className={styles.EshkaTaxiImage8}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img9}
    className={styles.EshkaTaxiImage9}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img10}
    className={styles.EshkaTaxiImage10}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img11}
    className={styles.EshkaTaxiImage11}
    alt="Eshka Taxi app interface"
  />,
  <img
    src={img12}
    className={styles.EshkaTaxiImage12}
    alt="Eshka Taxi app interface"
  />,
  ]
  return (
    <div className={styles.EshkaTaxi}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.behance.net/gallery/151442201/Eshka-Taxi-Mobile-Application'/>
    </div>
  )
}

export default EshkaTaxi
