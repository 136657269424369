import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/Outspace/1.jpg';
import img2 from '../images/Portfolio/Outspace/2.jpg';
import img3 from '../images/Portfolio/Outspace/3.png';
import img4 from '../images/Portfolio/Outspace/4.jpg';
import img5 from '../images/Portfolio/Outspace/5.png';
import img6 from '../images/Portfolio/Outspace/6.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/Outspace.module.scss'

function Outspace() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Outspace'
  const text =
    `Outspace is outstaff company, that connects business with performers in the field of digital products. The mobile app helps the customer to better know the wishes of the client for further cooperation. The client in the application can quickly fill out an interactive brief, attach references, and leave a request for further order.`
  const swiperImages = [
    <img
      src={img1}
      className={styles.OutspaceImage1}
      alt="Outspace web interface"
    />,
    <img
      src={img2}
      className={styles.OutspaceImage2}
      alt="Outspace web interface"
    />,
    <img
      src={img3}
      className={styles.OutspaceImage3}
      alt="Outspace web interface"
    />,
    <img
      src={img4}
      className={styles.OutspaceImage4}
      alt="Outspace web interface"
    />,
    <img
      src={img5}
      className={styles.OutspaceImage5}
      alt="Outspace web interface"
    />,
    <img
      src={img6}
      className={styles.OutspaceImage6}
      alt="Outspace web interface"
    />,
    <img
      src={img1}
      className={styles.OutspaceImage1}
      alt="Outspace web interface"
    />,
    <img
      src={img2}
      className={styles.OutspaceImage2}
      alt="Outspace web interface"
    />,
    <img
      src={img3}
      className={styles.OutspaceImage3}
      alt="Outspace web interface"
    />,
    <img
      src={img4}
      className={styles.OutspaceImage4}
      alt="Outspace web interface"
    />,
    <img
      src={img5}
      className={styles.OutspaceImage5}
      alt="Outspace web interface"
    />,
    <img
      src={img6}
      className={styles.OutspaceImage6}
      alt="Outspace web interface"
    />,
  ]
  return (
    <div className={styles.Outspace}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.behance.net/gallery/148469335/Mobile-App-Design-for-IT-company'/>
    </div>
  )
}

export default Outspace
