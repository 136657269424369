import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/Mysimplelog/1.jpg';
import img2 from '../images/Portfolio/Mysimplelog/2.jpg';
import img3 from '../images/Portfolio/Mysimplelog/3.jpg';
import img4 from '../images/Portfolio/Mysimplelog/4.jpg';
import img5 from '../images/Portfolio/Mysimplelog/5.jpg';
import img6 from '../images/Portfolio/Mysimplelog/6.jpg';
import img7 from '../images/Portfolio/Mysimplelog/7.jpg';
import img8 from '../images/Portfolio/Mysimplelog/8.jpg';
import img9 from '../images/Portfolio/Mysimplelog/9.jpg';
import img10 from '../images/Portfolio/Mysimplelog/10.jpg';
import img11 from '../images/Portfolio/Mysimplelog/11.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/Mysimplelog.module.scss'

function Mysimplelog() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Mysimplelog'
  const text =
    'An educational economic platform is a digital platform designed to provide learners with an in-depth understanding of economic principles and concepts. Our UI/UX design studio embarked on this journey to craft an interface that seamlessly merges economic knowledge with user-centric design.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.MysimplelogImage1}
      alt="Mysimplelog web interface"
    />,
    <img
      src={img2}
      className={styles.MysimplelogImage2}
      alt="Mysimplelog web interface"
    />,
    <img
      src={img3}
      className={styles.MysimplelogImage3}
      alt="Mysimplelog web interface"
    />,
    <img
      src={img4}
      className={styles.MysimplelogImage4}
      alt="Mysimplelog web interface"
    />,
    <img
      src={img5}
      className={styles.MysimplelogImage5}
      alt="Mysimplelog web interface"
    />,
    <img
      src={img6}
      className={styles.MysimplelogImage6}
      alt="Mysimplelog web interface"
    />,
    <img
    src={img7}
    className={styles.MysimplelogImage7}
    alt="Mysimplelog web interface"
  />,
  <img
    src={img8}
    className={styles.MysimplelogImage8}
    alt="Mysimplelog web interface"
  />,
  <img
    src={img9}
    className={styles.MysimplelogImage9}
    alt="Mysimplelog web interface"
  />,
  <img
    src={img10}
    className={styles.MysimplelogImage10}
    alt="Mysimplelog web interface"
  />,
  <img
  src={img11}
  className={styles.MysimplelogImage11}
  alt="Mysimplelog web interface"
  />,
  ]
  return (
    <div className={styles.Mysimplelog}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://mysimplelog.ch/index.html'/>
    </div>
  )
}

export default Mysimplelog
