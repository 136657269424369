import React, { useRef } from 'react'
import AboutUs from '../components/AboutUs'
import Footer from '../components/Footer'
import Portfolio from '../components/Portfolio'
import Header from '../components/Header'
import HeaderSafari from '../components/HeaderSafari'
import styles from '../styles/Homepage.module.scss'
import {isSafari} from 'react-device-detect'

function Homepage() {
  const portfolioRef = useRef(null)


  return (
    <div className={styles.Homepage}>
      {isSafari ? <HeaderSafari portfolioRef={portfolioRef}/>  : <Header portfolioRef={portfolioRef} />}
      <Portfolio portfolioWrapperRef={portfolioRef}/>
      <AboutUs />
      <Footer />
    </div>
  )
}

export default Homepage
