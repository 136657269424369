import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/Usign/1.jpg';
import img2 from '../images/Portfolio/Usign/2.jpg';
import img3 from '../images/Portfolio/Usign/3.jpg';
import img4 from '../images/Portfolio/Usign/4.jpg';
import img5 from '../images/Portfolio/Usign/5.jpg';
import img6 from '../images/Portfolio/Usign/6.jpg';
import img7 from '../images/Portfolio/Usign/7.jpg';
import img8 from '../images/Portfolio/Usign/8.jpg';
import img9 from '../images/Portfolio/Usign/9.jpg';
import img10 from '../images/Portfolio/Usign/10.jpg';
import img11 from '../images/Portfolio/Usign/11.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/Usign.module.scss'

function Usign() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Usign'
  const text =
    'Usign - Highly loaded service which allows users to sign and transfer documents within the same company. A lot of work has been done in building the logic of the service - the main component of the service. A laconic, light design was invented, which allows people of all ages to use the service. The admin panel and the interface of the archivist were also developed.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.UsignImage1}
      alt="Usign web interface"
    />,
    <img
      src={img2}
      className={styles.UsignImage2}
      alt="Usign web interface"
    />,
    <img
      src={img3}
      className={styles.UsignImage3}
      alt="Usign web interface"
    />,
    <img
      src={img4}
      className={styles.UsignImage4}
      alt="Usign web interface"
    />,
    <img
      src={img5}
      className={styles.UsignImage5}
      alt="Usign web interface"
    />,
    <img
      src={img6}
      className={styles.UsignImage6}
      alt="Usign web interface"
    />,
    <img
    src={img7}
    className={styles.UsignImage7}
    alt="Usign web interface"
  />,
  <img
    src={img8}
    className={styles.UsignImage8}
    alt="Usign web interface"
  />,
  <img
    src={img9}
    className={styles.UsignImage9}
    alt="Usign web interface"
  />,
  <img
    src={img10}
    className={styles.UsignImage10}
    alt="Usign web interface"
  />,
  <img
  src={img11}
  className={styles.UsignImage11}
  alt="Usign web interface"
/>,
    
  ]
  return (
    <div className={styles.Usign}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.behance.net/argus-soft'/>
    </div>
  )
}

export default Usign
