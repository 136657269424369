import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/EnlilMill/1.jpg';
import img2 from '../images/Portfolio/EnlilMill/2.jpg';
import img3 from '../images/Portfolio/EnlilMill/3.jpg';
import img4 from '../images/Portfolio/EnlilMill/4.jpg';
import img5 from '../images/Portfolio/EnlilMill/5.jpg';
import img6 from '../images/Portfolio/EnlilMill/6.jpg';
import img7 from '../images/Portfolio/EnlilMill/7.jpg';
import img8 from '../images/Portfolio/EnlilMill/8.jpg';
import img9 from '../images/Portfolio/EnlilMill/9.jpg';
import img10 from '../images/Portfolio/EnlilMill/10.jpg';
import img11 from '../images/Portfolio/EnlilMill/11.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/EnlilMill.module.scss'

function EnlilMill() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const title = 'Enlil mill'
  const text =
    'We were lucky to work with the Enlil Mill complex - a company engaged in the production of flour, the creation of products from it and its sale. The task was set to develop new packaging for their products, work on the Instagram account of the company (create a visual for it), as well as redesign an adaptive website.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.EnlilMillImage1}
      alt="Enlil Mill web interface"
    />,
    <img
      src={img2}
      className={styles.EnlilMillImage2}
      alt="Enlil Mill web interface"
    />,
    <img
      src={img3}
      className={styles.EnlilMillImage3}
      alt="Enlil Mill web interface"
    />,
    <img
      src={img4}
      className={styles.EnlilMillImage4}
      alt="Enlil Mill web interface"
    />,
    <img
      src={img5}
      className={styles.EnlilMillImage5}
      alt="Enlil Mill web interface"
    />,
    <img
      src={img6}
      className={styles.EnlilMillImage6}
      alt="Enlil Mill web interface"
    />,
    <img
    src={img7}
    className={styles.EnlilMillImage7}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img8}
    className={styles.EnlilMillImage8}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img9}
    className={styles.EnlilMillImage9}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img10}
    className={styles.EnlilMillImage10}
    alt="Enlil Mill web interface"
  />,
  <img
  src={img11}
  className={styles.EnlilMillImage11}
  alt="Enlil Mill web interface"
  />,
  <img
  src={img1}
  className={styles.EnlilMillImage1}
  alt="Enlil Mill web interface"
  />,
  <img
    src={img2}
    className={styles.EnlilMillImage2}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img3}
    className={styles.EnlilMillImage3}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img4}
    className={styles.EnlilMillImage4}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img5}
    className={styles.EnlilMillImage5}
    alt="Enlil Mill web interface"
  />,
  <img
    src={img6}
    className={styles.EnlilMillImage6}
    alt="Enlil Mill web interface"
  />,
  <img
  src={img7}
  className={styles.EnlilMillImage7}
  alt="Enlil Mill web interface"
  />,
  <img
  src={img8}
  className={styles.EnlilMillImage8}
  alt="Enlil Mill web interface"
  />,
  <img
  src={img9}
  className={styles.EnlilMillImage9}
  alt="Enlil Mill web interface"
  />,
  <img
  src={img10}
  className={styles.EnlilMillImage10}
  alt="Enlil Mill web interface"
  />,
  <img
  src={img11}
  className={styles.EnlilMillImage11}
  alt="Enlil Mill web interface"
  />,
    
  ]
  return (
    <div className={styles.EnlilMill}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='http://enlil.com.ua/en'/>
    </div>
  )
}

export default EnlilMill
