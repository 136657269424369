import React from 'react'
import styles from '../styles/PortfolioItem.module.scss'
import { Link } from 'react-router-dom'

function PortfolioItem({ image, color, tag, link, filtered }) {
  let backgroundColorClassName = 'PortfolioItemImageWrapper' + color
  return (
    <Link onClick={() => {
      /* eslint-disable */
      gtag('event', 'click', {
        event_category: 'outbound',
        event_label: link,
      })
    }} to={link} className={styles.PortfolioItem}>
      <div
        className={`${styles[color]} ${styles.PortfolioItemImageWrapper} ${styles[backgroundColorClassName]}`}
      >
        <div className={styles.PortfolioItemImageHover}>
          <img
            src={image}
            className={styles.PortfolioItemImage}
            alt="Portfolio example"
          />
          <div className={styles.PortfolioItemImageHoverTxt}>
            See full case study
          </div>
         </div>

        <div className={styles.PortfolioItemTagWrapper}>
          {tag &&
            tag.map((el, index) => {
              return (
                <div className={`${styles.PortfolioItemTagElement} ${filtered.includes(el) ? styles.PortfolioItemTagElementFilter : ""}`} key={index}>
                  {el}
                </div>
              )
            })}
        </div>
      </div>
    </Link>
  )
}

export default PortfolioItem
