import PortfolioPage from '../components/PortfolioPage'
import img1 from '../images/Portfolio/BankGrant/1.jpg';
import img2 from '../images/Portfolio/BankGrant/2.jpg';
import img3 from '../images/Portfolio/BankGrant/3.jpg';
import img4 from '../images/Portfolio/BankGrant/4.jpg';
import img5 from '../images/Portfolio/BankGrant/5.jpg';
import img6 from '../images/Portfolio/BankGrant/6.jpg';
import img7 from '../images/Portfolio/BankGrant/7.jpg';

import React, { useEffect } from 'react'
import styles from '../styles/BankGrant.module.scss'

function BankGrant() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const title = 'Bank Grant'
  const text =
    'UI/UX design was provided s or a mobile application for the Ukrainian bank "Grant". The app offers users digital access to the bank\'s services through a convenient and user-friendly interface. Throughout the project, we focused on creating an intuitive and visually appealing design that would enhance the overall user experience.'
  const swiperImages = [
    <img
      src={img1}
      className={styles.BankGrantImage1}
      alt="BankGrant web interface"
    />,
    <img
      src={img2}
      className={styles.BankGrantImage2}
      alt="BankGrant web interface"
    />,
    <img
      src={img3}
      className={styles.BankGrantImage3}
      alt="BankGrant web interface"
    />,
    <img
      src={img4}
      className={styles.BankGrantImage4}
      alt="BankGrant web interface"
    />,
    <img
      src={img5}
      className={styles.BankGrantImage5}
      alt="BankGrant web interface"
    />,
    <img
      src={img6}
      className={styles.BankGrantImage6}
      alt="BankGrant web interface"
    />,
    <img
    src={img7}
    className={styles.BankGrantImage7}
    alt="BankGrant web interface"
  />,
  <img
  src={img1}
  className={styles.BankGrantImage1}
  alt="BankGrant web interface"
  />,
  <img
    src={img2}
    className={styles.BankGrantImage2}
    alt="BankGrant web interface"
  />,
  <img
    src={img3}
    className={styles.BankGrantImage3}
    alt="BankGrant web interface"
  />,
  <img
    src={img4}
    className={styles.BankGrantImage4}
    alt="BankGrant web interface"
  />,
  <img
    src={img5}
    className={styles.BankGrantImage5}
    alt="BankGrant web interface"
  />,
  <img
    src={img6}
    className={styles.BankGrantImage6}
    alt="BankGrant web interface"
  />,
  <img
  src={img7}
  className={styles.BankGrantImage7}
  alt="BankGrant web interface"
  />,
  ]
  return (
    <div className={styles.BankGrant}>
      <PortfolioPage title={title} text={text} images={swiperImages} src='https://www.behance.net/gallery/170939433/Bank-Grant-Mobile-application'/>
    </div>
  )
}

export default BankGrant
